@tailwind base;
@layer base {
  h1 {
    @apply text-2xl;
    @apply font-semibold;
  }
  h2 {
    @apply text-xl;
    @apply font-medium;
  }
  h3 {
    @apply text-lg;
    @apply font-normal;
  }
}
@tailwind components;
@tailwind utilities;

@import '/src/assets/scss/variable.scss';

body,
html {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//Poppins
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

.ant-select .ant-select-arrow {
  transition: transform 0.2s ease-in;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $color_primary;
}

.ant-picker:hover,
.ant-picker-focused,
.ant-btn:hover,
.ant-btn:focus {
  border-color: $color_primary;
  box-shadow: none;
}

.ant-picker-suffix {
  color: $color-black;
}

.ant-table-content,
textarea {
  &::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px $color-grey-3;
    border: solid 4px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px $color-grey-5;
    border: solid 4px transparent;
    border-radius: 14px;
  }
}

::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px $color-grey-3;
  border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px $color-grey-5;
  border: solid 4px transparent;
  border-radius: 14px;
}

// icomoon
@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon/icomoon.eot');
  src: url('./assets/fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.woff') format('woff'), url('./assets/fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-icomoon'],
[class*=' icon-icomoon'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $color-white inset !important;
}
