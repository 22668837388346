@import 'src/assets/scss/responsive.scss';
@import 'src/assets/scss/variable.scss';

#layout {
  height: auto;
  #slider {
    @include mobile {
      display: none;
    }
  }
  .sider {
    position: sticky;
    left: 0;
    height: 100vh;
    top: 0;
    border-right: 1px solid $color-grey-5;
    .logo-section {
      max-height: 130px;
    }

    .main-menu {
      height: fit-content;
      border-right: none;
      .ant-menu-submenu-title,
      .ant-menu-submenu-arrow {
        color: $color_neutral;
        left: 0;
        padding: 0 calc(50% - 16px / 2);
        text-overflow: clip;

        & > span {
          flex: auto;
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        background-color: $color-grey-5;
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        font-weight: 500;
        img {
          filter: invert(23%) sepia(72%) saturate(1853%) hue-rotate(191deg) brightness(104%) contrast(86%);
        }
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-open {
        background: none;
        border: none;

        span {
          font-weight: 500;
          font-size: 16px;
          color: $color-blue;
        }

        img {
          filter: invert(23%) sepia(72%) saturate(1853%) hue-rotate(191deg) brightness(104%) contrast(86%);
        }

        &::after {
          right: 5px;
          left: 5px;
          border: none;
        }
      }
      .ant-menu-sub {
        background-color: $color-white;
      }
      .sub-menu-container {
        .sub-menu-container__item.active {
          color: $color-blue;
          .ant-menu-title-content {
            color: $color-blue;
          }
        }

        .ant-menu-title-content {
          font-weight: normal;
          font-size: 16px;
          border-radius: 10px;
          color: $color_neutral;
          padding-left: 20px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

        .ant-menu-item-active,
        .ant-menu-item-selected {
          span {
            font-size: 16px;
            border-radius: 10px;
            color: $color-blue;
            font-weight: 500;
          }
        }
      }
    }
    .ant-menu {
      font-size: 16px;
      border-radius: 0;

      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 14px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 14px 14px $color-grey-3;
        border: solid 4px transparent;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 14px 14px $color-grey-5;
        border: solid 4px transparent;
        border-radius: 14px;
      }
    }
  }

  .sider-collapse {
    @extend .sider;

    .ant-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: none;
      }
    }

    .social_media-small-icon {
      width: 14px;
      height: 14px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
