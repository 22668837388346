@import '../../assets/scss/variable.scss';

#layout {
  font-family: 'Poppins', sans-serif !important;
}

#layout .container {
  min-height: 100vh;
  height: fit-content;
}

#layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.header {
  padding: 34px 0 4px 0;

  .sidebar-icon {
    width: 20px;
    height: 14px;
    cursor: pointer;
  }

  .frac-icon {
    width: 100px;
    height: 38px;
    margin-left: 18px;
  }
}

.site-layout .site-layout-background {
  min-height: calc(100vh - 68px);
}

.public-header {
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $color-white;
  padding: 0 49px;
}
.page-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
