@import './colors';
@import './variable.scss';

.light {
  .theme-header {
    color: inherit;
  }
}

.dark {
  .theme-header {
    color: inherit;
  }
}

.one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.two-line {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.text-error {
  color: $color_error;
}

.m-0 {
  margin: 0;
}
