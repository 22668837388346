@import 'src/assets/scss/variable.scss';

.site-layout-header {
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  padding: 0;
  padding: 0 30px;
  z-index: 9;
  border-bottom: 1px solid #f4f4f4;
  position: sticky;
  top: 0;
  height: 68px;
}
