// common color of app
$color_primary: #1464af;
$color_primary-hover: #1780e2;
$color-primary-light: #71cff5;

$color_secondary: #f35b37;
$color_secondary-hover: #ec5f59;
$color-secondary-light: #71cff5;

$color-black: #000;
$color-white: #fff;
$color-natual: #242424;

// red
$color-error: #ef4444;
$text-color-red: #ff4848;

// gray
$color-grey: gray;
$color-grey-2: #878787;
$color-grey-3: #f7f7f7;
$color-grey-4: #787878;
$color-grey-5: #d1d1d1;
$color-grey-6: #303030;
$color_neutral: #454545;

// blue
$color-blue: #1890ff;

// table color
$border-color-table: #d1d1d1;

// inputs
$color8: #6197ff;
$color9: #4ecc79;
$color10: #fdc932;
$color11: #f35b37;
